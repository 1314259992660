import { ValueFormatterFunc } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatDate, formatDateTime, formatDateUTC, formatTimestampUTC } from '../../../apis/localization/localization-service';
import { ITooltipParams } from 'ag-grid-community';

export const ENUM_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value?.toUpperCase();

export const DATE_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDate(params.value) : '';

export const DATE_UTC_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDateUTC(params.value) : '';

export const DATE_TIME_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDateTime(params.value) : '';

export const FORMATTED_VALUE_TOOLTIP_GETTER = (params: ITooltipParams) =>
  params.valueFormatted

export const STRING_TOOLTIP_VALUE_GETTER = (params: ITooltipParams) =>
  params.valueFormatted || ['string', 'number', 'boolean'].includes(typeof params.value) ? params.value : undefined;

export const TIMESTAMP_UTC_TOOLTIP_VALUE_GETTER = (params: ITooltipParams) =>
  params.value ? formatTimestampUTC(params.value) : '';
