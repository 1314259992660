import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { PartsDataModel, RowState } from '../../../../apis/masterdata/parts-data-model';

export const PartModelRowStateRenderer = (params: ICellRendererParams<PartsDataModel, RowState>) => {
  return params.value ? `<span class='${ mapRowStateIcon(params) }' />` : null;
};

function mapRowStateIcon(params: ICellRendererParams<PartsDataModel, RowState>) {
  switch (params.value) {
    case 'OPEN':
      return 'wtt-icon-part-state-new';
    case 'PROCESSED':
      return 'wtt-icon-part-state-processed';
    case 'RELEASED':
      return 'wtt-icon-part-state-approved';
    case 'SENT':
      return 'wtt-icon-part-state-sent';
  }
}
