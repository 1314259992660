import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

export class WttDropDownCellEditor implements ICellEditorComp {
  value!: string;
  selectElement!: HTMLWttSelectElement;

  init(params: ICellEditorParams & { values: string[] }) {
    this.value = params.value;
    this.selectElement = document.createElement('wtt-select');
    this.selectElement.items = params.values.map(value => ({
      value, translationKey: {
        key: value,
      },
    }));
    this.selectElement.value = params.value;
    this.selectElement.addEventListener('itemSelect', (event) => {
      this.value = event.detail.newVal.value;
      params.stopEditing();
    });
  }

  getGui() {
    return this.selectElement;
  }

  afterGuiAttached() {
    this.selectElement.focus();
  }

  getValue() {
    return this.value;
  }

  isPopup() {
    return true;
  }
}
